// config.js
const config = {
  FRONTEND_URL: 'https://car.neuronsit.in',
  BACKEND_URL: "https://apiwheel.neuronsit.in",
  //FRONTEND_URL: "http://localhost:3000",
  //BACKEND_URL: 'http://localhost:5000',
  APP_NAME: "Wheelman",
  STRIPE_PUBLISHABLE_KEY: 'pk_test_Pyu4oj7fU3CFtbcP0gH16Ila',
  STRIPE_SECRET_KEY: 'sk_test_y70bYOyeXF1jzAkFnEXLrbau', 
  
  
};

export default config;
